.QuestionAccordion {
  position: relative;

  &._active {
    &::after {
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: #1976d2;
      content: '';
    }

    &._inner_before::after {
      top: -5px;
    }

    &._inner_after::after {
      bottom: -15px;
    }

    &._before::after {
      top: -25px;
    }

    &._after::after {
      bottom: -25px;
    }
  }
}
